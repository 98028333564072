import React from "react";
import { Loader, FooterComponent } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Row, Container, Col, ListGroup, ButtonGroup } from "react-bootstrap";
import { premiumExpiresToString } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
// Todo: Error redirect/page

export function Menu(props) {
	const [user] = React.useState(props.user || []);
	const [guilds] = React.useState(props.guilds || []);
	const [loading] = React.useState(props.loading);
	const navigate = useNavigate();

	function handleClick(path) {
		navigate(path);
	}

	return (
		<div className="menu-page">
			<Loader loading={loading} />
			{!loading && (
				<Container>
					<Row>
						{guilds
							.filter((guild) => {
								return guild.isBotInServer;
							})
							.map((guild) => (
								<Col xl={3} lg={4} md={4} sm={6} xs={6} className="clearfix py-3" key={guild.id}>
									<Card className="text-center" bg="dark">
										<Link to={{ pathname: `/dashboard/${guild.id}`, state: { user: user } }} key={"card" + guild.id}>
											{guild.settings.premium && <FontAwesomeIcon icon={faCircleCheck} size="2x" className="premium-icon" alt="Server has premium status" />}
											{guild.icon ? <Card.Img variant="top" src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}?size=256`} /> : <Card.Img variant="top" src="/images/default.png" />}
											<div className={"hover-toggle"}>
												<ListGroup variant="flush">
													<ListGroup.Item>Auto-View: {guild.settings.autoViewed ? "On" : "Off"}</ListGroup.Item>
													<ListGroup.Item>Premium: {guild.settings.premium ? "Yes!" : "Nope"}</ListGroup.Item>
													{guild.settings.premium && <ListGroup.Item>Premium Expires: {premiumExpiresToString(guild.settings.premiumExpires)}</ListGroup.Item>}
												</ListGroup>
											</div>
										</Link>
										<Card.Body>
											<ButtonGroup vertical>
												<Button variant="primary" size="lg" onClick={() => handleClick(`/dashboard/${guild.id}`)}>
													{guild.isBotInServer ? guild.name : `Invite to ${guild.name}`}
												</Button>
												{!guild.settings.premium && (
													<Button variant="primary" size="lg" onClick={() => handleClick(`/premium/${guild.id}`)}>
														{`Get Premium!`}
													</Button>
												)}
											</ButtonGroup>
										</Card.Body>
									</Card>
								</Col>
							))}
					</Row>
					<Row>
						{guilds
							.filter((guild) => {
								return !guild.isBotInServer;
							})
							.map((guild) => (
								<Col xl={3} lg={4} md={4} sm={6} xs={6} className="clearfix py-3" key={guild.id}>
									<a target="_blank" rel="noopener noreferrer" href={`https://discord.com/oauth2/authorize?client_id=709271563110973451&permissions=1073835072&scope=bot&guild_id=${guild.id}`}>
										<Card className="text-center" bg="dark">
											{guild.icon ? <Card.Img variant="top" src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}?size=256`} /> : <Card.Img variant="top" src="/images/default.png" />}
											<Card.Body>
												<Button variant="primary" size="lg">
													{guild.isBotInServer ? guild.name : `Invite to ${guild.name}`}
												</Button>
											</Card.Body>
										</Card>
									</a>
								</Col>
							))}
					</Row>
				</Container>
			)}
			{!loading && <FooterComponent />}
		</div>
	);
}
