import React from "react";
import { Image, CardGroup, Card, Button, Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faListOl, faCog, faCheckSquare, faBullhorn, faEye, faTrophy, faBrush, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faImdb, faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FooterComponent } from "../../components";
import { getBotServers } from "../../utils/utils";
import CountUp from "react-countup";

export function Homepage(props) {
	const [serverCount, setServerCount] = React.useState(0);

	React.useEffect(() => {
		getBotServers()
			.then(function (response) {
				if (response && response.data && response.data.count) {
					setServerCount(response.data.count);
				}
			})
			.catch(function (err) {
				setServerCount(36000);
			});
	}, []);
	return (
		<div>
			<div style={{ backgroundColor: "rgb(44, 41, 41)", color: "white", marginBottom: "-30px", paddingBottom: "100px !important" }} className="jumbotron text-center">
				<h1 className="display-1">
					Movie Night Bot <br />
					For Discord
				</h1>
				<br />
				<h3 id="serverCount" style={{ color: "white" }}>
					{serverCount === 0 ? (
						<b>Loading...</b>
					) : (
						<>
							Used in{" "}
							<b>
								<CountUp start={0} end={serverCount} duration={2} />
							</b>{" "}
							servers
						</>
					)}
				</h3>
				<br />
				<Button
					href="https://discord.com/api/oauth2/authorize?client_id=709271563110973451&permissions=2147560512&scope=applications.commands%20bot"
					target="_blank"
					rel="noopener noreferrer"
					variant="primary"
					size="lg"
					style={{ width: "400px", marginLeft: "auto", marginRight: "auto" }}
				>
					ADD TO DISCORD <FontAwesomeIcon icon={faDiscord} />
				</Button>
			</div>
			<Container>
				<CardGroup className="text-center Box-Shadow">
					<Card>
						<div style={{ width: "100%", paddingTop: "1.25rem", paddingLeft: "1.25rem", paddingRight: "1.25rem" }}>
							<FontAwesomeIcon icon={faListOl} size="4x" />
						</div>
						<Card.Body>
							<Card.Title>Maintain Lists Of Movies</Card.Title>
							<Card.Text>Allows users in your discord to submit movies to a server maintained list. With Premium use tags for better management.</Card.Text>
						</Card.Body>
					</Card>
					<Card>
						<div style={{ width: "100%", paddingTop: "1.25rem", paddingLeft: "1.25rem", paddingRight: "1.25rem" }}>
							<FontAwesomeIcon icon={faThumbsUp} size="4x" />
						</div>
						<Card.Body>
							<Card.Title>Poll Voting System</Card.Title>
							<Card.Text>Users vote on what to watch using our polls; with numerous settings!</Card.Text>
						</Card.Body>
					</Card>
					<Card>
						<div style={{ width: "100%", paddingTop: "1.25rem", paddingLeft: "1.25rem", paddingRight: "1.25rem" }}>
							<FontAwesomeIcon icon={faCog} size="4x" />
						</div>
						<Card.Body>
							<Card.Title>Custom Settings</Card.Title>
							<Card.Text>Numerous settings that admins can set to customse the bot to any servers needs!</Card.Text>
						</Card.Body>
					</Card>
					<Card>
						<div style={{ width: "100%", paddingTop: "1.25rem", paddingLeft: "1.25rem", paddingRight: "1.25rem" }}>
							<FontAwesomeIcon icon={faCheckCircle} size="4x" />
						</div>
						<Card.Body>
							<Card.Title>Premium Features</Card.Title>
							<Card.Text>Poll specific movies, use tags, get autocomplete results and more in future features!</Card.Text>
						</Card.Body>
					</Card>
				</CardGroup>
				<Row className="mt-5 info-panel">
					<Col className="feature-image" lg={6}>
						<Image fluid className="image-shadow" src="/images/cropped-confirmation.png" style={{ width: "90%" }} />
					</Col>
					<Col className="flex-col" lg={6}>
						<h3>Add Command</h3>
						<p className="header-sub">Will search online databases and display all the movies details to you!</p>
						<Row>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faCheckSquare} size="3x" color="#cb7630" />
								<h5>Confirmation Prompt</h5>
								<p>Will ask user to confirm if the movie we found is what they intend to search for.</p>
							</Col>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faImdb} size="3x" color="#cb7630" />
								<h5>IMDB Links Supported!</h5>
								<p>Use IMDB links to be specific when adding a movie.</p>
							</Col>
						</Row>
						<Row>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faList} size="3x" color="#cb7630" />
								<h5>Details Stored</h5>
								<p>Will ask user to confirm if the movie we found is what they intend to search for.</p>
							</Col>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faBrush} size="3x" color="#cb7630" />
								<h5>Movie Poster</h5>
								<p>Use IMDB links to be specific when adding a movie.</p>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-5 info-panel">
					<Col className="flex-col">
						<h3>Get/List Command</h3>
						<p className="header-sub">Gets a list of all the movies currently in the servers list. Viewed command can also be used to see all the movies that have been viewed before.!</p>
					</Col>
					<Col className="feature-image">
						<Image fluid className="image-shadow" src="/images/cropped-list.png" />
					</Col>
				</Row>
				<Row className="mt-5 info-panel">
					<Col lg={6} className="feature-image">
						<Image fluid className="image-shadow" src="/images/cropped-poll.png" style={{ width: "90%" }} />
					</Col>
					<Col className="flex-col" lg={6}>
						<h3>Poll Command</h3>
						<p className="header-sub">Begins a poll in the server with up to a max of 10 random movies from the servers list. This can also be set to less movies if required.</p>
						<Row>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faBullhorn} size="3x" color="#cb7630" />
								<h5>Custom Announcements</h5>
								<p>Set custom announcement for when polls begin, even mention a specific role!</p>
							</Col>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faThumbsUp} size="3x" color="#cb7630" />
								<h5>ONE VOTE PER PERSON OR MULTIPLE!</h5>
								<p>Settings allow people to vote for multiple movies, or one at most!</p>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="mt-5 info-panel">
					<Col className="flex-col" lg={6}>
						<h3>Winner</h3>
						<p className="header-sub">Winner of the film is announced after the poll ends.</p>
						<Row>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faEye} size="3x" color="#cb7630" />
								<h5>Auto-View Setting</h5>
								<p>Admins are able to set whether movies are automatically moved to the 'Viewed' list after a successful poll.</p>
							</Col>
							<Col className="feature-card">
								<FontAwesomeIcon icon={faTrophy} size="3x" color="#cb7630" />
								<h5>Winner Announced</h5>
								<p>Set custom time poll should be available, after this a winning film will be announced</p>
							</Col>
						</Row>
					</Col>
					<Col lg={6} className="feature-image">
						<Image fluid className="image-shadow" src="/images/cropped-win.png" />
					</Col>
				</Row>
				<div class="text-center"><h3>Premium Features and Benefits</h3></div>
							<Row className="mt-5 info-panel">
								<Col className="feature-image" lg={6}>
									<Image fluid className="image-shadow" src="/images/cropped-autocomplete.png" style={{ width: "90%" }} />
								</Col>
								<Col className="flex-col" lg={6}>
									<h3>Autocomplete Results</h3>
									<p className="header-sub">For certain commands when searching your movies, get autocomplete results so you choose the right movie everytime.</p>
								</Col>
							</Row>
							<Row className="mt-5 info-panel">
								<Col className="flex-col">
									<h3>Tags</h3>
									<p className="header-sub">Add custom tags to seperate movies by genre, or anything else. </p>
								</Col>
								<Col className="feature-image">
									<Image fluid className="image-shadow" src="/images/cropped-tags.png" />
								</Col>
							</Row>
							<Row className="mt-5 info-panel">
								<Col lg={6} className="feature-image">
									<Image fluid className="image-shadow" src="/images/premium-poll.png" style={{ width: "90%" }} />
								</Col>
								<Col className="flex-col" lg={6}>
								<h3>Premium Poll Options</h3>
									<p className="header-sub">Poll for specific movies, or by custom tags setup by you!</p>
								</Col>
							</Row>
							<Row className="mt-5 info-panel">
								<Col className="flex-col">
									<h3>Bulk Add</h3>
									<p className="header-sub">Add multiple movies at once. Currently limited to 10 per command, and only using IMDB links while beta testing. </p>
								</Col>
								<Col className="feature-image">
									<Image fluid className="image-shadow" src="/images/bulk-add.png" />
								</Col>
							</Row>
			</Container>
			<FooterComponent />
		</div>
	);
}
