import React from "react";
import { FooterComponent, ErrorComponent, Loader } from "../../components";
import { useParams, useNavigate } from "react-router-dom";

export function PaymentRedirect(props) {
	const params = useParams();
	const [loading, setLoading] = React.useState(true);
	const [guilds] = React.useState(props.guilds || []);
	const [error, setError] = React.useState(null);
	const currentGuild = guilds.find((guild) => guild.id === params.id) || {};
	const navigate = useNavigate();

	React.useEffect(() => {
		setLoading(true);

		if (currentGuild.id) {
			//Do not get user details if we already have them. Need to stop hitting API twice.
			setLoading(false);
		} else {
			setError("You do not have access to this guild.");
			setLoading(false);
		}
	}, [params.id, currentGuild.id]);

	return (
		<div>
			<div className="payment-redirect">
				<Loader loading={loading} />
				{!loading && !error && (
					<>
						<div style={{ backgroundColor: "rgb(44, 41, 41)", color: "white", paddingBottom: "100px" }} className="jumbotron text-center">
							<h1 className="display-1">Thanks for your support!</h1>
							<br />
							<br />
							<h1>Server: {currentGuild.name} subscription will be applied within a few minutes.</h1>
							<br />
							<p>Email movienightbot1@gmail.com for support if any issues occur.</p>
							<p>Please include the Server ID {currentGuild.id} and any other details that may help.</p>
						</div>
					</>
				)}
				{!loading && error && <ErrorComponent message={error} func={goBack} />}
				{!loading && <FooterComponent />}
			</div>
		</div>
	);

	function goBack() {
		try {
			navigate(-1);
		} catch (e) {
			window.location.href = process.env.REACT_APP_BASE_URL;
		}
	}
}
