import React from "react";
import { Container, Button, NavDropdown, Row, Col, Card, Image } from "react-bootstrap";
import { FooterComponent, ErrorComponent, Loader } from "../../components";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

export function Premium(props) {
	const login = function () {
		window.location.href = `${process.env.REACT_APP_API_FULLURL}auth/discord`;
	};
	const user = props.user || null;
	const params = useParams();
	const [loading, setLoading] = React.useState(true);
	const [guilds] = React.useState(props.guilds || []);
	const [error] = React.useState(null);
	const currentGuild = guilds.find((guild) => guild.id === params.id) || {};
	const [selectedGuild, setSelectedGuild] = React.useState(null);
	const [selectedPlan, setSelectedPlan] = React.useState("y");
	const navigate = useNavigate();

	function isUserLoggedIn() {
		return user != null && user !== undefined;
	}

	React.useEffect(() => {
		setLoading(false);

		if (currentGuild.name) {
			setSelectedGuild(currentGuild);
		}
	}, [params.id, currentGuild.id]);

	return (
		<div>
			<div className="payment-page">
				<Loader loading={loading} />
				{!loading && !error && (
					<>
						<Container>
							<div style={{ backgroundColor: "rgb(47 49 54)", color: "white" }} className="text-center">
								<br />
								<h1 className="display-1">Thanks for your support!</h1>
								<br />
								<br />
								<h3>Unlock extra features for your server, and support development!</h3>
								<br />
								<br />

								{!isUserLoggedIn() && !loading && (
									<Button onClick={login} variant="outline-primary">
										LOGIN WITH <FontAwesomeIcon icon={faDiscord} />
									</Button>
								)}
								{isUserLoggedIn() && !loading && (
									<NavDropdown
										className="payment-dropdown"
										title={
											selectedGuild != null ? (
												<div className="dropdown-avatar">
													<img style={{ height: "128px" }} alt="" className="rounded-circle" src={selectedGuild.icon ? `https://cdn.discordapp.com/icons/${selectedGuild.id}/${selectedGuild.icon}?size=256` : "/images/default.png"} />
													<div style={{ display: "inline-flex", fontSize: "2.25em", verticalAlign: "middle" }} className="guild-name">
														{selectedGuild.name || ""}
													</div>
												</div>
											) : (
												<div style={{ display: "inline-flex", fontSize: "2.25em", verticalAlign: "middle" }} className="guild-name">
													Select a Server
												</div>
											)
										}
									>
										{guilds.map((guild) => (
											<NavDropdown.Item key={guild.id} onClick={() => setSelectedGuild(guild)}>
												<img alt="" className="rounded-circle" src={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}?size=256` : "/images/default.png"} />
												<div className="guild-name">{guild.name}</div>
											</NavDropdown.Item>
										))}
									</NavDropdown>
								)}
								<br />
								<br />

								<Row>
									<Col xl={6} lg={6} md={6} sm={6} xs={6} className="clearfix py-3" key={"y"}>
										<Card className={`text-center pointer ${selectedPlan === "y" ? "active" : ""}`} onClick={() => setSelectedPlan("y")} bg="dark">
											<Card.Header>
												<h2>Year Plan</h2>
											</Card.Header>
											<Card.Body>
												This plan is NOT recurring!
												<br />
												<sup className="text-info" style={{ fontSize: "1.1rem" }}>
													$
												</sup>
												<span style={{ fontSize: "4rem" }} className="text-info">
													8
												</span>
												<sup className="text-info" style={{ fontSize: "1.1rem" }}>
													{" "}
													AUD
												</sup>
												<br />
												<span className="text-white-50">for one Year</span>
											</Card.Body>
										</Card>
									</Col>
									<Col xl={6} lg={6} md={6} sm={6} xs={6} className="clearfix py-3" key={"l"}>
										<Card className={`text-center pointer ${selectedPlan === "l" ? "active" : ""}`} onClick={() => setSelectedPlan("l")} bg="dark">
											<Card.Header>
												<h2>Lifetime Plan</h2>
											</Card.Header>
											<Card.Body>
												One time payment!
												<br />
												<sup className="text-info" style={{ fontSize: "1.1rem" }}>
													$
												</sup>
												<span style={{ fontSize: "4rem", position: "relative" }} className="text-info">
    												<span style={{textDecoration: "line-through", top: "50%", transform: "translateY(-50%)"}}>25</span>
    												<sup className="text-info" style={{fontSize: "1.1rem"}}>$</sup>
													<span style={{position: "relative"}}>16</span>
												</span>
												<sup className="text-info" style={{ fontSize: "1.1rem" }}>
													{" "}
													AUD
												</sup>
												<br />
												<span className="text-white-50">forever!</span>
											</Card.Body>
										</Card>
									</Col>
								</Row>

								<Button
									size="lg"
									onClick={async () => {
										if (!selectedPlan || !selectedGuild) {
											if (!isUserLoggedIn()) return alert("Please login to select a server.");
											alert("Please select a server and payment plan.");
										} else {
											const response = await fetch(`${process.env.REACT_APP_API_FULLURL}premium/pay-checkout`, {
												credentials: "include",
												method: "POST",
												headers: {
													"Content-Type": "application/json",
												},
												body: JSON.stringify({
													subscriptionType: selectedPlan,
													guildID: selectedGuild.id,
												}),
											});

											// Retrieve the checkout link from the response
											const { checkoutLink } = await response.json();

											// Redirect the user to the checkout page
											window.location.href = checkoutLink;
										}
									}}
								>
									Proceed to Checkout (Powered by Square)
								</Button>
							</div>
							<br />
							<br />
							<div class="text-center">
								<h3>Premium Features and Benefits</h3>
							</div>
							<Row className="mt-5 info-panel">
								<Col className="feature-image" lg={6}>
									<Image fluid className="image-shadow" src="/images/cropped-autocomplete.png" style={{ width: "90%" }} />
								</Col>
								<Col className="flex-col" lg={6}>
									<h3>Autocomplete Results</h3>
									<p className="header-sub">For certain commands when searching your movies, get autocomplete results so you choose the right movie everytime.</p>
								</Col>
							</Row>
							<Row className="mt-5 info-panel">
								<Col className="flex-col">
									<h3>Tags</h3>
									<p className="header-sub">Add custom tags to seperate movies by genre, or anything else. </p>
								</Col>
								<Col className="feature-image">
									<Image fluid className="image-shadow" src="/images/cropped-tags.png" />
								</Col>
							</Row>
							<Row className="mt-5 info-panel">
								<Col lg={6} className="feature-image">
									<Image fluid className="image-shadow" src="/images/premium-poll.png" style={{ width: "90%" }} />
								</Col>
								<Col className="flex-col" lg={6}>
									<h3>Premium Poll Options</h3>
									<p className="header-sub">Poll for specific movies, or by custom tags setup by you!</p>
								</Col>
							</Row>
							<Row className="mt-5 info-panel">
								<Col className="flex-col">
									<h3>Bulk Add</h3>
									<p className="header-sub">Add multiple movies at once. Currently limited to 10 per command, and only using IMDB links while beta testing. </p>
								</Col>
								<Col className="feature-image">
									<Image fluid className="image-shadow" src="/images/bulk-add.png" />
								</Col>
							</Row>
						</Container>
					</>
				)}
				{!loading && error && <ErrorComponent message={error} func={goBack} />}
				{!loading && <FooterComponent />}
			</div>
		</div>
	);

	function goBack() {
		try {
			navigate(-1);
		} catch (e) {
			window.location.href = process.env.REACT_APP_BASE_URL;
		}
	}
}
